import React, { useEffect, useState } from 'react';
import "./UserStyles.css";
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import { useUsersContext } from '../../hooks/UsersContext';
import ToggleActiveButton from '../../components/Buttons/ToggleActiveButton';
import axiosConfig from '../../utils/axiosConfig';

function UserInfo() {
    const { selectedUser,refreshUsers,updateUsersInContext } = useUsersContext();
    const [profile,setProfile]=useState();

    // Helper function to render user information item
    const renderInfoItem = (title, value) => (
        <div className='info-item'>
            <p className='info-title'>{title}</p>
            <p className='info-description'>{value || 'لا يوجد'}</p>
        </div>
    );


    const fetchSelectedUserProfile = async () => {
        try {
            const response = await axiosConfig.get('/user/profile');
            console.log("User Profile: ", response.data.user);
            console.log("User Role: ", response.data.user.roles[0].name);

            if (response.status === 200) {
                // console.log(response.data.message,"get All plans");
                // console.log("res: ", response);
                // console.log("res: ", response.data.data);
                // setProfile(response.data.posts.original);
                // setNetError(null);
            } else {
                console.log("error ", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching plans:", error);
            // setNetError("حدث خطأ أثناء التحميل. يرجى المحاولة مرة أخرى.");
        }finally{
            // setNetLoading(false)
        }
    };
    useEffect(()=>{
        fetchSelectedUserProfile();
    },[selectedUser])

    return (
        <div className='user-details'>
            <p className='title'>تفاصيل المستخدم</p>
            <div className='details-table'>
                {/* {selectedUser && ( */}
                {!selectedUser ? (
                    <div>No User Selected</div>
                ) : (
                    <>
                        {renderInfoItem('اسم المستخدم', selectedUser.name)}
                        {renderInfoItem('البريد الالكتروني', selectedUser.email)}
                        {renderInfoItem('تاريخ الاشتراك', selectedUser.created_at.split("T")[0])}
                        {selectedUser.plan && selectedUser.plan.plan && (
                            <>
                                {renderInfoItem('نوع الباقة', selectedUser.plan.plan.ar_name)}
                                {renderInfoItem('عدد الدعوات', selectedUser.plan.count_of_message && selectedUser.plan.plan.count_of_message ? `${selectedUser.plan.count_of_message} من ${selectedUser.plan.plan.count_of_message}` : ' ')}
                            </>
                        )}
                        {selectedUser.plan && !selectedUser.plan.plan && (
                            <>
                                {renderInfoItem('نوع الباقة', 'لا يوجد')}
                                {/* {renderInfoItem('عدد الدعوات', 'لا يوجد')} */}
                            </>
                        )}
                            <div className='info-item'>
                            {/* {selectedUser.plan && !selectedUser.plan.plan && (
                            <>
                                {renderInfoItem('نوع الباقة', 'لا يوجد')}
                            </>
                        )} */}
                          
                            {/* <Button
                                t_color={colors.white}
                                b_color={colors.rejected}
                                className="dis-active-user"
                            >
                                إيقاف الحساب
                            </Button> */}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default UserInfo;