import React, { useState } from 'react'
import AuthLayout from '../../layouts/AuthLayout'
import axios from "../../utils/axiosConfig"
import { FaUser } from "react-icons/fa6";
import { FaLock } from "react-icons/fa";
import './LoginFrom.css'
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import Form from '../../components/Forms/Form';
import { useNavigate } from 'react-router-dom';
import Edit from "../../assets/icons/GoldEdit.svg"
import Cookies from 'js-cookie';
import CustomAlert from '../../components/Alert/CustomAlert';


function LoginFrom() {
    
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error ,setError]=useState(null);

    const fields = [
      {placeholder:' ادخل اسم المستخدم',type: 'text', name: 'email' },
      {placeholder:' ادخل كلمة السر ', type: 'password', name: 'password' },
    ];

    const handleErrorClose = () => {
      setError(null);
    };

    const loginSubmit = async (formData) => {
      console.log("form " ,formData)
      
      try {
        setLoading(true);
        const response = await axios.post('/login', formData);
        // console.log(response.data);
        Cookies.set('access_token', response.data.msg);
        setError(null)
        navigate("/users");
      } catch (error) {
        console.error('Error occurred during login:', error);
        setError('Error occurred during login')
        // Handle login error
      }
      finally {
        setLoading(false);
      }
    };

  return (
    <>
    <Form
  form_className="login-form-container"
  form_title="تسجيل الدخول"
   fields={fields}
   showLink={true}
  //  linkNav="/register"
  //  linkText="انشاء حساب ادمن جديد "
   btn_className="login-btn"
   btn_text={loading ? "يتم تسجيل الدخول..." : "تسجيل الدخول"}
   disabled={loading}
   onSubmit={loginSubmit}
  />
      {error !== null && <CustomAlert message={error}  onClose={handleErrorClose} />}
    </>
  
  )
}

export default LoginFrom
