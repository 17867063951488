import Users from "../../assets/icons/Users.svg"
import ColoredUsers from "../../assets/icons/ColoedUsers.svg"
import Plans from "../../assets/icons/Plans.svg"
import ColoredPlans from "../../assets/icons/ColoredPlans.svg"
import Contacts from "../../assets/icons/Contacts.svg"
import ColoredContacts from "../../assets/icons/ColoredContacts.svg"
import Areas from "../../assets/icons/Areas.svg"
import ColoredAreas from "../../assets/icons/ColoredAreas.svg"

export const MenuItems = [
    {
      title: "إدارة المستخدمين",
      url: "/users",
      cName: "nav-links",
      // icon: "fa fa-users"
      icon : Users,
      selectedIcon: ColoredUsers
    },
    {
      title: "إدارة الباقات",
      url: "/plans",
      cName: "nav-links",
      // icon: "fa fa-tags"
      icon : Plans,
      selectedIcon: ColoredPlans
    },
    {
      title: "قائمة الأسماء",
      url: "/contacts",
      cName: "nav-links",
      // icon: "fa fa-list"
      icon : Contacts,
      selectedIcon: ColoredContacts
    },
    {
      title: "إدارة المناطق",
      url: "/areas",
      cName: "nav-links",
      // icon: "fa fa-home"
      icon : Areas,
      selectedIcon: ColoredAreas
    },
];
  