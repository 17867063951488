import React, { useEffect } from 'react'
import './NavigatorbarsStyles.css';
import colors from '../Colors/color';
import { MenuItems } from './MenuItems';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useContactsContext } from '../../hooks/ContantsContext';
import { useUsersContext } from '../../hooks/UsersContext';
import { usePlansContext } from '../../hooks/PlansContext';
import { useRegionsContext } from '../../hooks/RegionsContext';
import withelogo from "../../assets/images/Mask group.svg";
import Button from '../Buttons/Button';


function ResponsiveSidebar() {
  const {areas,refreshAreas, fetchAreas} =useRegionsContext();
  const {contacts ,refreshContacts} = useContactsContext();
  const {users,refreshUsers} = useUsersContext();
  const { plans,refreshPlans} = usePlansContext();
    const navigate = useNavigate();
    const location =useLocation();

    const goToPrivacy = () => {
        navigate('/Privacy_Policies');
      };
    
      const goToTerms = () => {
        navigate('/Terms');
      };

      const refreshData = () => {
        refreshAreas();
        refreshContacts();
        refreshUsers();
        refreshPlans();
      };
    
      useEffect(() => {
        refreshData();
      }, [location.pathname]);

      const handleMenuItemClick = (item) => {
        switch (item.url) {
          case "/areas":
            refreshAreas(); // Refresh areas data
            break;
          case "/contacts":
            refreshContacts(); // Refresh contacts data
            break;
          case "/usres":
            refreshUsers(); // Refresh contacts data
            break;
          case "/plans":
            refreshPlans(); // Refresh contacts data
            break;
          // Add cases for other menu items as needed
          default:
            // Handle other menu items
        }
      };


  return (
    <div className='sidebar_container'  style={{ backgroundColor: colors.gold, color: colors.white }}>
       <a className="sideBar_logo" style={{ color: 'inherit' }}>
              <img alt='logo' src={withelogo} />
            </a>
        <ul className='sidebar_list'>
       
        {MenuItems.map((item, index) => {
            return (
                <li key={index}>
                 <Link
                    className={location.pathname === item.url ? 'activetap' : 'tab'}
                     onClick={() => handleMenuItemClick(item)}
                    to={item.url}>
                          <span className='sidebar_icon'>
                 <img src={location.pathname === item.url ? item.selectedIcon : item.icon} />
               </span>
       <span className='sidebar_text'>{item.title}</span>
                    </Link>
                </li>
        
           ) })}

        </ul>
        {/* <div className='footer_btn'>
              <div className='terms'>
                <Button
                  t_color={colors.white}
                  b_color={colors.tab_gold}
                  className="terms-btn"
                  onClick={goToPrivacy}
                >
                  الخصوصية
                </Button>
                <Button
                  t_color={colors.white}
                  b_color={colors.tab_gold}
                  className="terms-btn"
                  onClick={goToTerms}
                >
                  الشروط
                </Button>
              </div>
            </div> */}

    </div>
  )
}

export default ResponsiveSidebar
