import React, { useState } from 'react';
import Button from '../Buttons/Button';
import "../../auth/Login/LoginFrom.css";
import colors from '../Colors/color';

const Form = ({ form_className, form_title, fields,btn_className, btn_text, onSubmit, showLink ,linkText,linkNav}) => {
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Call the onSubmit callback with the form data
    onSubmit(formData);
  };

  return (
    <div className={form_className}>
      <h2>{form_title}</h2>
      <form onSubmit={handleSubmit}>
        {fields && fields.map((field, index) => (
          <div key={index}>
            <label htmlFor={field.name}>{field.label}</label>
            {field.type === 'select' ? (
              // <select
              //   id={field.name}
              //   name={field.name}
              //   value={formData[field.name] || ''}
              //   onChange={handleChange}
              //   {...field.attributes}
              // >
              //   <option value="">Select {field.label}</option>
              //   {field.options && field.options.map((option, optionIndex) => (
              //     <option key={optionIndex} value={formData[option.value]|| ''}>{option.label}</option>
              //   ))}

              // </select>
              <select
              id={field.name}
              name={field.name}
              value={formData[field.name] || ''} // Ensure correct value is selected based on formData
              onChange={handleChange}
              {...field.attributes}
            >
              <option value="">اختر {field.label}</option>
              {field.options && field.options.map((option, optionIndex) => (
                <option key={optionIndex} value={option.value}>{option.label}</option> // Use option.value directly
              ))}
            </select>
          
            ) : (
           
            <input
              type={field.type}
              placeholder={field.placeholder}
              id={field.name}
              name={field.name}
              value={formData[field.name] || ''}
              onChange={handleChange}
              {...field.attributes}
            />
            )}
          </div>
        ))}
        {showLink && (
          <div className="link">
            <a href={linkNav}>{linkText}</a>
          </div>
        )}
        <Button className={btn_className} t_color={colors.light_gold} b_color={colors.white}>
          {btn_text}
        </Button>
      </form>
    </div>
  );
};

export default Form;
