import React, { useState } from 'react'
import './NavigatorbarsStyles.css';
import axios from "../../utils/axiosConfig"
import Button from '../Buttons/Button';
import colors from '../Colors/color';
import { useNavigate } from 'react-router-dom';

function Upbar({ page_title, upBarContent }) {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const logoutSubmit = async(e) => {
    e.preventDefault();
    // localStorage.removeItem('access_token');
    try {
      setLoading(true);
      const res = await axios.post('/user/logout');
      if (res.data.success === 1) {
        localStorage.removeItem('access_token');
        console.log(res.data.msg, "res")
        navigate('/login');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('An error occurred during logout:', error);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <nav className="nav-container">
      <h5>{page_title}</h5>
      <div className='buttons'>
      <Button
        t_color={colors.white}
        b_color={colors.light_gold}
        className="logout-btn"
        onClick={logoutSubmit}
      >
        {loading ? "يتم تسجيل الخروج..." : "تسجيل الخروج"}
      </Button>
      {upBarContent}
      </div>
        </nav>
  )
}

export default Upbar
