import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MyModal from '../../components/Popup/MyModal';
import Form from '../../components/Forms/Form';
import axios from '../../utils/axiosConfig';
import CustomAlert from "../../components/Alert/CustomAlert";
// import AddContact from '../Contact/AddContact';
import { useContactsContext } from '../../hooks/ContantsContext';
import { useUsersContext } from '../../hooks/UsersContext';

function AddArea({ onClose ,newAreaName }) {
  const formRef = useRef(null);
  const {refreshUsers,setSelectedUser} = useUsersContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [id, setID] =useState (newAreaName.id);
  const [areas_id, setAreas_id] = useState("");


  const fields = [
    { label: 'اسم المنطقة بالعربي', type: 'text', name: 'ar_name' },
    // { label: 'تفعيل المنطقة', type: 'checkbox', name: 'is_active' },
    // { label: 'اسم المنطق بالعبري', type: 'text', name: 'he_name' },
  ];
  const handleErrorClose = () => {
    setError(null);
  };

  const handleSubmit = async (areas_id) => {
    try {
      setLoading(true);
      const response = await axios.put(`/contact/edit`, { id:newAreaName.id , areas_id:areas_id , areas_notes:" " });
      console.log("res edit area user " , response)
      setSelectedUser(null);
      refreshUsers();
    //  const
      onClose(); // Close the modal after successful form submission
    } catch (error) {
      console.error('Error occurred during form submission:', error);
      setError(error.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const createArea = async (formData) => {
    try {
      setLoading(true);
      const response = await axios.post('/area/add', formData); // Adjust the API endpoint as needed
      console.log("res Area", response.data); // Handle the response data as needed  
      console.log("area",response.data.data.id)
      setError(null);
      console.log(newAreaName ,"newAreaName.....")
      if (newAreaName) {
        // setAreas_id(response.data.data.id)
        handleSubmit(response.data.data.id)
      }
      // {newAreaName && openAddContactPopup();}
      onClose(); // Close the modal after successful form submission
    } catch (error) {
      console.error('Error occurred during form submission:', error);
      setError(error.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='modal-categories'>
      <MyModal
        title="Add Area"
        show={true} // Always keep the modal open
        onHide={onClose}
        body={
          <>
            {newAreaName &&
            <p style={{ backgroundColor: "#eee", display: "inline-block",
             padding: "10px", borderRadius: "5px" , fontWeight: "bold" }}>
            اسم المدينة المضاف :
             <span style={{color: "#AB9354" ,paddingInlineStart:"10px"}}>
             {newAreaName.areas_notes}
              </span>
            </p>
           }
            <Form
              form_className="contact-form-container"
              form_title="إضافة منطقة"
              fields={fields}
              formRef={formRef}
              onSubmit={createArea}
              btn_className='add_from_popup'
              btn_text={loading ? "تتم لإضافة..." : "إضافة"}
              disabled={loading}
            />
             {error !== null && <CustomAlert message={error}  onClose={handleErrorClose} />}
          </>
        }
      />
    </div>
  );
}

export default AddArea;
