// import React, { useEffect, useState } from 'react';
// import HomeLayout from '../../layouts/HomeLayout';
// import Button from '../../components/Buttons/Button';
// import colors from '../../components/Colors/color';
// import TopLayout from '../../layouts/TopLayout';
// import { usePlansContext } from '../../hooks/PlansContext';
// import AddPlan from './AddPlan';
// import EditIcon from "../../assets/icons/GoldEdit.svg"
// import axiosConfig from '../../utils/axiosConfig';
// import SearchBar from '../../components/SearchBar/SearchBar';
// import Pagination from '../../components/Paginations/Pagination';
// import PackageCard from './Packages/PackageCard';
// import "./PlansStyles.css"
// import { useLocation, useNavigate } from 'react-router-dom';
// import CustomAlert from '../../components/Alert/CustomAlert';

// function PlanPage() {

//     const {plans,refreshPlans,netError,setNetError,netLoading} = usePlansContext();

//     const [addModalShow, setAddModalShow] = useState(false);
//     const navigate = useNavigate();
//     const location = useLocation();

//     const [currentPage, setCurrentPage] = useState(1);
//     const cardsPerPage = 6;

//     const openAddPopup = () => {
//         setAddModalShow(true);
//         navigate(`${location.pathname}/addNewPlan`,{ replace : false })
//     };

//     const closeAddPopup = () => {
//         setAddModalShow(false);
//         refreshPlans();
//         navigate('/plans')
//     };
//     const upBarContent = (
//         <Button
//             t_color={colors.white}
//             b_color={colors.light_gold}
//             className="new-contact-btn"
//             onClick={openAddPopup}
//         >
//             إضافة باقة 
//         </Button>
//     );

//     const EditButton = (plan) => (
//         <>
//             <Button
//                 className="edit_icon"
//                 // onClick={() => openEditPopup(plan)}
//             >
//                <img src={EditIcon} />
//             </Button>
//         </>
//     );
//     const [searchResults, setSearchResults] = useState([]); // State to store search results
//     const handleSearch = async (query) => {
//         if (!query) {
//             setSearchResults(null); // If there's no query, clear search results
//         } else {
//             try {
//                 const response = await axiosConfig.get(`/plans?keyword=${query}`); // Replace with your search API endpoint
//                 setSearchResults(response.data.data); // Set search results from API response
//                 console.log(response.data.data, "res");
//             } catch (error) {
//                 console.error('Error searching Plans:', error);
//                 // Handle error
//             }
//         }
//     };
//     const packageCards = plans && plans.map((plan, index) => (
//         // <div className="cards" key={index}>
//             <PackageCard plan={plan}/>
//         // </div>
//     ));
//         // Calculate total number of pages
//         const totalPages = Math.ceil(plans.length / cardsPerPage);
// console.log("totalPages " , totalPages)

//         // Function to handle page change
//         const handlePageChange = (page) => {
//             setCurrentPage(page);
//         };
    
       
// const handleNetError = ()=>{
//     setNetError(null);
//     refreshPlans();
// }

//     return (
//         <>
//             <HomeLayout
//                 page_title="إدارة الباقات"
//                 upBarContent={upBarContent}
//                 mainContent={
//                     <TopLayout
//                         className="top_full"
//                         title="الباقات"
//                         // searchBar={<SearchBar  onSearch={handleSearch}/>}
//                         children={
//                             netLoading ? (
//                                 <p>جاري التحميل...</p>
//                             ):
//                             netError ? (
//                                 <div>
//                                     <CustomAlert
//                                         message={netError}
//                                         btnName="أعد المحاولة"
//                                         onClose={handleNetError}
//                                     />
                                   
//                                 </div>
//                             ) : 
//                             // loading ?(
//                             //         <p>جاري البحث...</p>
//                             // ): 
//                          plans.length>0 && plans ? (
//                             <div className='content'>
//                             <div className='packages'>
//                                 <div  className="cards">
//                                 {/* {visibleCards} */}
//                                 {packageCards}
//                                 </div>
//                             </div>
//                     <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
//                 </div>):("لا يوجد باقات")
//                         }
//                     />}
//             />
//             {addModalShow && <AddPlan onClose={closeAddPopup} />}

//         </>
//     );
// }

// export default PlanPage;

import React, { useState, useEffect } from 'react';
import HomeLayout from '../../layouts/HomeLayout';
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import TopLayout from '../../layouts/TopLayout';
import { usePlansContext } from '../../hooks/PlansContext';
import AddPlan from './AddPlan';
import EditIcon from "../../assets/icons/GoldEdit.svg";
import axiosConfig from '../../utils/axiosConfig';
import SearchBar from '../../components/SearchBar/SearchBar';
import Pagination from '../../components/Paginations/Pagination';
import PackageCard from './Packages/PackageCard';
import "./PlansStyles.css";
import { useLocation, useNavigate } from 'react-router-dom';
import CustomAlert from '../../components/Alert/CustomAlert';

function PlanPage() {
    const { plans, refreshPlans, netError, setNetError, netLoading } = usePlansContext();
    const [addModalShow, setAddModalShow] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const cardsPerPage = 6;
    const [searchResults, setSearchResults] = useState([]); // State to store search results
    const [loading, setLoading] = useState(false);

    const openAddPopup = () => {
        setAddModalShow(true);
        navigate(`${location.pathname}/addNewPlan`, { replace: false });
    };

    const closeAddPopup = () => {
        setAddModalShow(false);
        refreshPlans();
        navigate('/plans');
    };

    const upBarContent = (
        <Button
            t_color={colors.white}
            b_color={colors.light_gold}
            className="new-contact-btn"
            onClick={openAddPopup}
        >
            إضافة باقة
        </Button>
    );

    const EditButton = (plan) => (
        <Button className="edit_icon">
            <img src={EditIcon} alt="Edit" />
        </Button>
    );

    const handleSearch = async (query) => {
        setLoading(true);
        if (!query) {
            setSearchResults([]); // If there's no query, clear search results
        } else {
            try {
                const response = await axiosConfig.get(`/plans?keyword=${query}`); // Replace with your search API endpoint
                setSearchResults(response.data.data); // Set search results from API response
                console.log(response.data.data, "res");
            } catch (error) {
                console.error('Error searching Plans:', error);
                // Handle error
            } finally {
                setLoading(false);
                setCurrentPage(1); // Reset to first page when search results change
            }
        }
    };

    // Get the plans to display based on pagination
    const plansToDisplay = searchResults.length > 0 ? searchResults : plans;
    const indexOfLastCard = currentPage * cardsPerPage ;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentPlans = plansToDisplay.slice(indexOfFirstCard, indexOfLastCard);

    const packageCards = currentPlans.map((plan, index) => (
        <PackageCard key={index} plan={plan} />
    ));

    // Calculate total number of pages
    const totalPages = Math.ceil(plansToDisplay.length / cardsPerPage);
    console.log(totalPages ,"total")

    // Function to handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleNetError = () => {
        setNetError(null);
        refreshPlans();
    };

    useEffect(() => {
        if (currentPage > totalPages) {
            setCurrentPage(totalPages); // Ensure the current page is valid
        }
    }, [currentPage]);
    
    useEffect (()=>{
        setCurrentPage(1); 
    },[])

    return (
        <>
            <HomeLayout
                page_title="إدارة الباقات"
                upBarContent={upBarContent}
                mainContent={
                    <TopLayout
                        className="top_full"
                        title="الباقات"
                        searchBar={<SearchBar onSearch={handleSearch} />}
                        children={
                            netLoading ? (
                                <p>جاري التحميل...</p>
                            ) : netError ? (
                                <div>
                                    <CustomAlert
                                        message={netError}
                                        btnName="أعد المحاولة"
                                        onClose={handleNetError}
                                    />
                                </div>
                            ) : loading ? (
                                <p>جاري البحث...</p>
                            ) : plansToDisplay.length > 0 ? (
                                <div className='content'>
                                    <div className='packages'>
                                        <div className="cards">
                                            {packageCards}
                                        </div>
                                    </div>
                                    <Pagination
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            ) : (
                                "لا يوجد باقات"
                            )
                        }
                    />
                }
            />
            {addModalShow && <AddPlan onClose={closeAddPopup} />}
        </>
    );
}
    
export default PlanPage;
