import React, { useState, useEffect } from 'react';
import MyModal from '../../components/Popup/MyModal';
import { useRegionsContext } from '../../hooks/RegionsContext';
// import createAxiosInstance from "../../utils/axiosConfig"
import axios from '../../utils/axiosConfig';
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import CustomAlert from '../../components/Alert/CustomAlert';

function EditArea({ onClose }) {
    const { selectedArea ,updateAreasInContext } = useRegionsContext();
    const [ar_name, setArName] = useState("");
    const [id, setID] = useState("");
    // const [en_name, setEnName] = useState("");
    // const [he_name, setHeName] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (selectedArea) {
            setArName(selectedArea.ar_name);
            setID(selectedArea.id)
            // setEnName(selectedArea.en_name);
            // setHeName(selectedArea.he_name);
        }
        console.log("selectedArea changed:", selectedArea);
    }, [selectedArea]);

    const handleErrorClose = () => {
        setError(null);
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true)
             await axios.put(`/area/edit`,{
                id,
                ar_name
            });
            setError(null);
            onClose();
        } catch (error) {
            console.error('Error occurred during form submission:', error);
            setError(error.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className='modal-categories'>
            <MyModal
                title="Edit Area"
                show={true}
                onHide={onClose}
                body={
                    <>
                     <div className='contact-form-container'>
                        {/* {error && <div className="error-message">{error}</div>} */}
                        <form onSubmit={handleSubmit}>
                                <label>اسم المنطقة بالعربي</label>
                                <input 
                                    type="text" 
                                    // className="form-control" 
                                    name="ar_name"
                                    value={ar_name} 
                                    onChange={(e) => setArName(e.target.value)} 
                                />
                           
                                {/* <label>اسم المنطقة بالانكليزي</label>
                                <input 
                                    type="text" 
                                    name="en_name"
                                    value={en_name} 
                                    onChange={(e) => setEnName(e.target.value)} 
                                />
                         
                                <label>اسم المنطقة بالعبري</label>
                                <input 
                                    type="text" 
                                    name="he_name"
                                    value={he_name} 
                                    onChange={(e) => setHeName(e.target.value)} 
                                /> */}

                            <div className='edit_buttons_div'>
                            <Button className="add_from_popup">
                            {loading ? "يتم التعديل..." : "تعديل المنطقة"}
        </Button>
        <Button className="cansle_from_popup" onClick={onClose} b_color={colors.rejected} t_color={colors.white}>
                            إلغاء
        </Button>
                           </div>
                        </form>
                    </div>
                    {error !== null && <CustomAlert message={error}  onClose={handleErrorClose} />}
                    </>
                }
            />
        </div>
    );
}

export default EditArea;

