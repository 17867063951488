const colors = {
    gold: '#AB9354', // Golden
    light_gold: '#DBB658', // Light gold
    extra_light_gold: '#F1E6C7', // Extra Light gold
    success: '#237763', // Green
    rejected: '#E33629', // Red
    white:  '#FFFFFF' ,//white
  };
  
  export default colors;
  