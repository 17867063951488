import React from 'react';
import PropTypes from 'prop-types';
import "./TableStyles.css";
import Button from '../Buttons/Button';
import MyModal from '../Popup/MyModal';
import colors from '../Colors/color';

function Table({ columns, data, children, responsiveClassName, tableClassName, childrenClassName, onRowClick ,selectedRowIndex }) {

  return (
    <div style={{backgroundColor:colors.extra_light_gold}}
     className={`table-responsive ${responsiveClassName ? responsiveClassName : ''}`}>
      <table className={`table-container ${tableClassName ? tableClassName : ''}`}>
        <thead>
          <tr>
            {columns && columns.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data && data.map((row, rowIndex) => (
            // console.log("data ", data),
            // console.log("row ", row),
            // console.log("rowIndex ", rowIndex),
            <tr key={rowIndex} onClick={() => onRowClick && onRowClick(rowIndex)}>
              {row && row.map((cell, cellIndex) => (
                <td key={cellIndex}
                style={{
                  backgroundColor: rowIndex === selectedRowIndex ? '#f0f0f0' : null, // Change color if selected
                  transition: 'background-color 0.3s ease'
              }}
                >
                  {/* <div className='cell-content'> */}
                  {cell}
                    {/* </div> */}
                    </td>
              ))}
              {children && <td className={`children ${childrenClassName ? childrenClassName : ''}`} colSpan={row.length}>{children}</td>}
            </tr>
          ))}
        </tbody>
      </table>  
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  children: PropTypes.element,
  responsiveClassName: PropTypes.string,
  tableClassName: PropTypes.string,
  childrenClassName: PropTypes.string,
  onRowClick: PropTypes.func
};

export default Table;
