import React, { Children } from 'react';
import "./ButtonStyles.css"

const Button = ({ onClick, children ,btn_type,t_color ,b_color ,className}) => {
  return (
    <button type={btn_type} style={{backgroundColor:b_color, color: t_color}}
            className={className}
            onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
