import React from 'react'
// import "../pages/Contact/BodyStyles.css";
import "./HomeLayoutStyles.css"
import colors from '../components/Colors/color';

function ButtomLayout({tableClassName,children}) {

  // const data = [
  //     [ 'name1', '+93', 'address'],
  //     [ 'name2', '+93', 'address'],
  //     [ 'name3', '+93', 'address']
  // ];

  return (
    <div className={`buttom_section  ${tableClassName ? tableClassName : ''}`} style={{ backgroundColor: colors.extra_light_gold }}>
      {/* <div className='user-details'>
        <p className='title'>تفاصيل المستخدم</p>
        <div className='details-table'>
  <div className='info-item'>
    <p className='info-title'>اسم المستخدم</p>
    <p className='info-description'>وسيم</p>
  </div>
  <div className='info-item'>
    <p className='info-title'>البريد الالكتروني</p>
    <p className='info-description'>وسيم</p>
  </div>
  <div className='info-item'>
    <p className='info-title'>تاريخ الاشتراك</p>
    <p className='info-description'>وسيم</p>
  </div>
  <div className='info-item'>
    <p className='info-title'>نوع الباقة</p>
    <p className='info-description'>وسيم</p>
  </div>
  <div className='info-item'>
    <p className='info-title'>عدد الدعوات</p>
    <p className='info-description'>وسيم</p>
  </div>
  <Button
    t_color={colors.white}
    b_color={colors.rejected}
    className="logout-btn"
  >
    إيقاف الحساب
  </Button>
</div>

      </div> */}
      {/* <UserInfo/>
      <AddedContact/> */}
      {/* <div className='added-contact'>
        <p className='title'>جهات الاتصال المضافة</p>
        <Table
                    // columns={columns} 
                    data={data} 
                    editMode={true} 
                    // extraButtons={extraButtons} 
                    // onEditRow={handleEditRow} 
                    // onSaveRow={handleSaveRow} 
                    // onCancelEdit={handleCancelEdit}
                    extraButtons={extraButtons}
                />
      </div> */}

      {children}
    </div>
  )
}

export default ButtomLayout
