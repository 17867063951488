// import React from 'react';
// import "./PaginationStyle.css"

// const Pagination = ({ totalPages, currentPage, onPageChange }) => {
//     const handlePageClick = (page) => {
//         onPageChange(page);
//     };

//     const renderPaginationItems = () => {
//         const items = [];
//         const maxPages = Math.min(totalPages, 3);
//         let startPage = 1;
//         if (totalPages > 3 && currentPage > 2) {
//             startPage = currentPage - 1;
//         }
//         for (let i = startPage; i < startPage + maxPages; i++) {
//             items.push(
//                 <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
//                     <a className="page-link" href="#" onClick={() => handlePageClick(i)}>
//                         {i}
//                     </a>
//                 </li>
//             );
//         }
//         return items;
//     };

//     return (
//         <div className="pagination-container">
//         <nav aria-label="...">
//             <ul className="pagination">
//                 <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                     <a className="page-link" href="#" onClick={() => handlePageClick(currentPage - 1)}>
//                         Previous
//                     </a>
//                 </li>
//                 {renderPaginationItems()}
//                 <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//                     <a className="page-link" href="#" onClick={() => handlePageClick(currentPage + 1)}>
//                         Next
//                     </a>
//                 </li>
//             </ul>
//         </nav>
//         </div>
//     );
// };

// export default Pagination;


import React from 'react';
import "./PaginationStyle.css"

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    const handlePageClick = (page) => {
        if (page !== currentPage && page > 0 && page <= totalPages) {
            onPageChange(page);
        }
    };

    const renderPaginationItems = () => {
        const items = [];
        let startPage = 1;
        let endPage = totalPages;

        if (totalPages > 3) {
            if (currentPage <= 2) {
                endPage = 3;
            } else if (currentPage >= totalPages - 1) {
                startPage = totalPages - 2;
            } else {
                startPage = currentPage - 1;
                endPage = currentPage + 1;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageClick(i)}>
                        {i}
                    </button>
                </li>
            );
        }
        return items;
    };

    return (
        <div className="pagination-container">
            <nav aria-label="Page navigation">
                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageClick(currentPage - 1)}>
                            Previous
                        </button>
                    </li>
                    {renderPaginationItems()}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageClick(currentPage + 1)}>
                            Next
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Pagination;
