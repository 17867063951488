// AppContext.js

import axios from "../utils/axiosConfig"
import React, { createContext, useContext, useEffect, useState } from 'react';

// Creating a context
const ContactsContext = createContext();

// Custom hook to use the context
export const useContactsContext = () => useContext(ContactsContext);

// Provider component to wrap around components that need access to the context
export const ContactsProvider = ({ children }) => {

    const [netError,setNetError] =useState(null);
    const [netLoading, setNetLoading] = useState(true);

    // Areas variables for regions data and loading status
    const [contacts, setContacts] = useState([]);
    const [selectedContant , setSelectedContact] = useState(null)
    // Function to fetch states in context
    const fetchContacts = async  (keyword = '', page = '', count = '') => {
        try {
            const url = `/contact/view?keyword=${keyword}&page=${page}&count=${count}`;
            const response = await axios.get(url);
            // console.log(response,"get");
            // console.log(response.data,"get All contacts");
            if (response.status === 200) {
                // console.log(response.data.message,"get All contacts");
                // console.log("res: ", response);
                // console.log("res: ", response.data.data);
                setContacts(response.data);
                setNetError(null);
            } else {
                console.log("error ", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching contacts:", error);
            setNetError("حدث خطأ أثناء التحميل. يرجى المحاولة مرة أخرى.");
        }finally{
            setNetLoading(false)
        }
    };
    // Function to update states in context
    const updateContactsInContext = (newContant) => {
        setContacts(newContant);
    };
    // Function to refresh states in context
    const refreshContacts = () => {
        // setSelectedContact(null)
        setNetLoading(true);
        fetchContacts();
    };
 

    useEffect(() => {
        fetchContacts();
    }, []);


    return (
        <ContactsContext.Provider
            value={{
             contacts,refreshContacts,updateContactsInContext,
             selectedContant,setSelectedContact,
             netError,setNetError,
             netLoading
            }}
        >
            {children}
        </ContactsContext.Provider>
    );
};
