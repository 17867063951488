// AppContext.js

import axios from "../utils/axiosConfig"
import React, { createContext, useContext, useEffect, useState } from 'react';

// Creating a context
const RegionsContext = createContext();

// Custom hook to use the context
export const useRegionsContext = () => useContext(RegionsContext);

// Provider component to wrap around components that need access to the context
export const RegionsProvider = ({ children }) => {

    const [netError,setNetError] =useState(null);
    const [netLoading, setNetLoading] = useState(true);


    // Areas variables for regions data and loading status
    const [areas, setAreas] = useState([]);
    const [selectedArea , setSelectedArea] = useState(null)
    // Function to fetch states in context
    const fetchAreas = async () => {
        setNetLoading(true)
        try {
            const response = await axios.get(`/area/view`);
            if (response.status === 200) {
                console.log(response.data,"get All Areas");
                // console.log("res: ", response);
                console.log("res: ", response.data.posts.original);
                setAreas(response.data.posts.original);
                setNetError(null);
            } else {
                console.log("error ", response.data);
            }
        } catch (error) {
            console.error("Error fetching areas:", error);
            setNetError("حدث خطأ أثناء التحميل. يرجى المحاولة مرة أخرى.");
        }finally{
            setNetLoading(false)
        }
    };
    // Function to update states in context
    const updateAreasInContext = (newArea) => {
        setAreas(newArea);
    };
    // Function to refresh states in context
    const refreshAreas = () => {
        setNetLoading(true);
        fetchAreas();
    };
 

    // useEffect(() => {
    //     fetchAreas();
    // }, []);


    return (
        <RegionsContext.Provider
            value={{
             areas,refreshAreas,updateAreasInContext,
             selectedArea,setSelectedArea,
             netError,setNetError,
             netLoading
            }}
        >
            {children}
        </RegionsContext.Provider>
    );
};
