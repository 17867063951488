// AppContext.js

import axios from "../utils/axiosConfig"
import React, { createContext, useContext, useEffect, useState } from 'react';

// Creating a context
const UsersContext = createContext();

// Custom hook to use the context
export const useUsersContext = () => useContext(UsersContext);

// Provider component to wrap around components that need access to the context
export const UserProvider = ({ children }) => {

    const [netError,setNetError] =useState(null);
    const [netLoading, setNetLoading] = useState(true);

    // Areas variables for regions data and loading status
    const [users, setUsers] = useState([]);
    const [selectedUser , setSelectedUser] = useState(null)
    // Function to fetch states in context
    const fetchUsers = async  (keyword = '', page = '', count = '') => {
        // setNetLoading(true);
        try {
            const url = `/user/view?keyword=${keyword}&page=${page}&count=${count}`;
            const response = await axios.get(url);
            // console.log(response.data.user.original,"get All users");
            if (response.status === 200) {
                // console.log(response.data.message,"get All users");
                // console.log("User res 1: ", response);
                // console.log("User res: ", response.data.data);
                
                setUsers(response.data.user.original);
                setNetError(null);
            } else {
                console.log("error ", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching users:", error);
            setNetError("حدث خطأ أثناء التحميل. يرجى المحاولة مرة أخرى.");
        }finally{
            setNetLoading(false)
        }
    };
    // Function to update states in context
    const updateUsersInContext = (newUser) => {
        setUsers(newUser);
    };
    // Function to refresh states in context
    const refreshUsers = () => {
        setSelectedUser(null)
        setNetLoading(true);
        fetchUsers();
    };


    useEffect(() => {
        fetchUsers();
    }, []);


    return (
        <UsersContext.Provider
            value={{
             users,refreshUsers,updateUsersInContext,
             selectedUser,setSelectedUser,
             netError,setNetError,
             netLoading
            }}
        >
            {children}
        </UsersContext.Provider>
    );
};
    