import React, { useState, useEffect } from 'react';
import MyModal from '../../components/Popup/MyModal';
import axios from '../../utils/axiosConfig';
import { useContactsContext } from '../../hooks/ContantsContext';
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import CustomAlert from '../../components/Alert/CustomAlert';
import { useRegionsContext } from '../../hooks/RegionsContext';

function EditContact({ onClose }) {
    const { selectedContant } = useContactsContext();
    const {areas} =useRegionsContext();
    const [id, setID] = useState("");
    const [firstname, setFirstName] = useState("");
    const [father_name, setFatherName] = useState("");
    const [lastname, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [areas_id, setAreas_id] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (selectedContant) {
            setID(selectedContant.id);
            setFirstName(selectedContant.firstname);
            setFatherName(selectedContant.father_name);
            setLastName(selectedContant.lastname);
            setPhone(selectedContant.phone);
            // setAreas_id(selectedContant.area[0].id)
            if (Array.isArray(selectedContant.area) && selectedContant.area.length > 0) {
                setAreas_id(selectedContant.area[0].id);
            }
        }
        console.log("selectedContant changed:", selectedContant.area[0].id);
    }, [selectedContant]);
    useEffect(()=>{
        console.log("111 changed:", areas_id);
    })

    const handleErrorClose = () => {
        setError(null);
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true)
            await axios.put(`/contact/edit`,{
                id,
                firstname,
                father_name,
                lastname,
                phone,
                areas_id
            });
            setError(null)
            onClose();
        } catch (error) {
            console.error('Error occurred during form submission:', error);
            setError(error.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className='modal-categories'>
            <MyModal
                title="Edit Area"
                show={true}
                onHide={onClose}
                body={
                    <>
                      <div className='contact-form-container'>
                        {/* {error && <div className="error-message">{error}</div>} */}
                        <form onSubmit={handleSubmit}>
                                <label>الاسم</label>
                                <input 
                                    type="text" 
                                    name="firstname"
                                    value={firstname} 
                                    onChange={(e) => setFirstName(e.target.value)} 
                                />
                                <label>اسم الأب</label>
                                <input 
                                    type="text" 
                                    name="father_name"
                                    value={father_name} 
                                    onChange={(e) => setFatherName(e.target.value)} 
                                />
                                <label>اسم العائلة</label>
                                <input 
                                    type="text" 
                                    name="lastname"
                                    value={lastname} 
                                    onChange={(e) => setLastName(e.target.value)} 
                                />
                                <label>رقم الهاتف</label>
                                <input 
                                    type="text" 
                                    name="phone"
                                    value={phone} 
                                    onChange={(e) => setPhone(e.target.value)} 
                                />
                                <label>المنطقة</label>
                                <select
                                value={areas_id}
                                onChange={(e)=>{setAreas_id(e.target.value)}}
                                >
                                {areas.map((area ,key) =>(
                                            <option value={area.area.id} key={key}>
                                                {area.area.ar_name}
                                            </option>
                                ))};
                                </select>
                            <div className='edit_buttons_div'>
                            <Button className="add_from_popup">
                            {loading ? "يتم التعديل..." : "تعديل الاسم"}
        </Button>
        <Button className="cansle_from_popup" onClick={onClose} b_color={colors.rejected} t_color={colors.white}>
                            إلغاء
        </Button>
                                {/* <button type="submit" className="btn btn-primary">{loading ? "Updating..." : "Update Contact"}</button>
                                <button type="button" className="btn btn-secondary ml-2" onClick={onClose}>Cancel</button> */}
                            </div>
                        </form>
                        </div>
                        {error !== null && <CustomAlert message={error}  onClose={handleErrorClose} />}
                    </>
                }
            />
        </div>
    );
}

export default EditContact;

