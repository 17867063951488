// import axios from 'axios';

// axios.defaults.baseURL = "https://wedding.api.onedelivery.cloud/api/v1/admin";
// axios.defaults.headers.common['Authorization'] = 'Auth Token';
// axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
// axios.defaults.headers.post['Accept'] = "application/json";
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";

// axios.interceptors.request.use(function (config) {
//   const token = localStorage.getItem('access_token');
//   config.headers.Authorization = token ? `Bearer ${token}` : '';
//   return config;
// });

// export default axios;



import axios from 'axios';
import Cookies from 'js-cookie';

// Function to create and configure Axios instance
const createAxiosInstance = () => {
  const instance = axios.create({
    // baseURL: 'https://wedding.api.onedelivery.cloud/api/v1/admin',
    baseURL: 'https://api.azimh.com/api',
    headers: {
      'Authorization': 'Auth Token',
      'Content-Type':'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  });

  // Add request interceptor
  instance.interceptors.request.use(function (config) {
    // try{
    //   const csrfToken = axios.get('https://api.azimh.com/api/sanctum/csrf-cookie')
    //   config.headers["X-XSRF-TOKEN"] = csrfToken;
    // }catch{
    //   console.log("419");
    // }
    const token = Cookies.get('access_token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  return instance;
};

// Export the singleton instance
export default createAxiosInstance();
