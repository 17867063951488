import React from 'react';
import './CustomAlert.css';
import Button from '../Buttons/Button';
import colors from '../Colors/color';

const CustomAlert = ({ message, onClose ,btnName }) => {
  console.log("Message: ", message);
  return (
    <div className="custom-alert">
      <div className="alert-content">
        <p>{message}</p>
        <Button
        className="close-alert-btn"
        onClick={onClose}
        b_color={colors.light_gold}
        t_color={colors.white}
        >
            {btnName ? btnName : "إغلاق"} 
        </Button>
      </div>
    </div>
  );
};

export default CustomAlert;
