import React, { useState, useEffect } from 'react';
import MyModal from '../../components/Popup/MyModal';
import axios from '../../utils/axiosConfig';
import { usePlansContext } from '../../hooks/PlansContext';
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import CustomAlert from '../../components/Alert/CustomAlert';

function EditPlan({ onClose }) {
    const { selectedPlan } = usePlansContext();
    const [id,setId] =useState("");
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [count_of_message, setCount_of_message] = useState("");
    const [currency, setCurrancy] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (selectedPlan) {
            setId(selectedPlan.id);
            setName(selectedPlan.name);
            setPrice(selectedPlan.price);
            setCount_of_message(selectedPlan.count_of_message);
            setCurrancy(selectedPlan.currency);
        }
        console.log("selectedPlan changed:", selectedPlan);
    }, [selectedPlan]);

    const handleErrorClose = () => {
        setError(null);
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true)
            await axios.put(`/plan/edit`,{
                id,
                name,
                price,
                count_of_message,
                currency
            }).then(response => {
                console.log(response.data);
                onClose();
              }).catch(error => {
                console.error(error);
              }); 
        } catch (error) {
            console.error('Error occurred during form submission:', error);
            setError(error.response?.data?.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className='modal-categories'>
            <MyModal
                title="Edit Area"
                show={true}
                onHide={onClose}
                body={
                    <>
                    <div className='contact-form-container'>
                    {/* {error && <div className="error-message">{error}</div>} */}
                        <form onSubmit={handleSubmit}>
                            {/* <div className="form-group"> */}
                                <label>اسم الباقة</label>
                                <input 
                                    type="text" 
                                    // className="form-control" 
                                    name="name"
                                    value={name} 
                                    onChange={(e) => setName(e.target.value)} 
                                />
                            {/* </div> */}
                            {/* <div className="form-group"> */}
                                <label>السعر</label>
                                <input 
                                    type="text" 
                                    // className="form-control" 
                                    name="price"
                                    value={price} 
                                    onChange={(e) => setPrice(e.target.value)} 
                                />
                            {/* </div> */}
                            {/* <div className="form-group"> */}
                                <label>عدد الرسائل</label>
                                <input 
                                    type="text" 
                                    // className="form-control" 
                                    name="count_of_message"
                                    value={count_of_message} 
                                    onChange={(e) => setCount_of_message(e.target.value)} 
                                />
                            {/* </div> */}
                            {/* <div className="form-group"> */}
                                <label>العملة</label>
                                <input 
                                    type="text" 
                                    // className="form-control" 
                                    name="currency"
                                    value={currency} 
                                    onChange={(e) => setCurrancy(e.target.value)} 
                                />
                            {/* </div> */}
                            <div className='edit_buttons_div'>
                            <Button className="add_from_popup">
                            {loading ? "يتم التعديل..." : "تعديل الباقة"}
        </Button>
        <Button className="cansle_from_popup" onClick={onClose} b_color={colors.rejected} t_color={colors.white}>
                            إلغاء
        </Button>
        
                                {/* <button type="submit" className="btn btn-primary">{loading ? "Updating..." : "Update Plan"}</button>
                                <button type="button" className="btn btn-secondary ml-2" onClick={onClose}>Cancel</button> */}
                            </div>
                        </form>
                    </div>
                    {error !== null && <CustomAlert message={error}  onClose={handleErrorClose} />}
                    </>
                }
            />
        </div>
    );
}

export default EditPlan;

