// // import './App.css';
// // import axios from './utils/axiosConfig'; // Import the Axios instance
// // import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
// // import AuthLayout from './layouts/AuthLayout';
// // import LoginFrom from './auth/Login/LoginFrom';
// // import MyComponent from './MyComponent';
// // import HomeLayout from './layouts/HomeLayout';
// // import ContactPage from './pages/Contact/ContactPage';
// // import UsersPage from './pages/Users/UsersPage';
// // import ContriesPage from './pages/Contries/ContriesPage';
// // import SignupFrom from './auth/Signup/SignupFrom';
// // import { useEffect } from 'react';

// // function App() {

// //   const token = localStorage.getItem('access_token');
// //   // localStorage.removeItem('access_token');
// //   const navigate = useNavigate();

// //   console.log("remi:  ", token)

// //   useEffect(() => {
// //     if (!token) {
// //       navigate('/login');
// //     }
// //   }, [token, navigate]);

// //   return (
// //     <Routes>
// //     <Route path="*" element={<div>Page not Found</div>} />
// //     <Route path="/login" element={<LoginFrom/>} />
// //     <Route path="/register" element={<SignupFrom/>} />
// //     <Route
// //               path="/"
// //               element={token ? <HomeLayout /> : <Navigate to="/login" replace />}
// //             />
// //     <Route path="/contacts" element={<ContactPage/>} />
// //     <Route path="/users" element={<UsersPage/>} />
// //     <Route path="/contries" element={<ContriesPage/>} />
// //   </Routes>
// //   );
// // }

// // export default App;



import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import AuthLayout from './layouts/AuthLayout';
import LoginForm from './auth/Login/LoginForm';
import SignupForm from './auth/Signup/SignupForm';
import HomeLayout from './layouts/HomeLayout';
import ContactPage from './pages/Contact/ContactPage';
import UsersPage from './pages/Users/UsersPage';
import AreasPage from './pages/Areas/AreasPage';
import { useEffect } from 'react';
import { RegionsProvider } from './hooks/RegionsContext';
import { UserProvider } from './hooks/UsersContext';
import { ContactsProvider } from './hooks/ContantsContext';
import PlanPage from './pages/Plans/PlanPage';
import { PlanProvider } from './hooks/PlansContext';
import Cookies from "js-cookie"


function App() {
  // const token = localStorage.getItem('access_token');
  const token = Cookies.get('access_token');
  console.log("token ",token);
  const location = useLocation();

  // useEffect(() => {
  //   // Check if the user is trying to access a protected route
  //   const protectedRoutes = ['/contacts', '/users', '/areas'];
  //   const isProtectedRoute = protectedRoutes.some(route => location.pathname.startsWith(route));

  //   // Redirect to login only if it's a protected route and the user is not authenticated
  //   if (isProtectedRoute && !token) {
  //     window.location.href = '/login';
  //   }
  // }, [token, location]);

  useEffect(() => {
    const protectedRoutes = ['/contacts', '/users', '/areas'];
  
    if (!token) {
      // If there's no token, redirect to login for protected routes
      if (protectedRoutes.some(route => location.pathname.startsWith(route))) {
        window.location.href = '/login';
      }
      // Redirect to login for the home route if there's no token
      if (location.pathname === '/') {
        window.location.href = '/login';
      }
    }
  }, [token, location]);

  return (
    <RegionsProvider>
      <ContactsProvider>
        <PlanProvider>
      <UserProvider>  
    <Routes>
      <Route path="*" element={<div>Page not Found</div>} />
      <Route path="/login" element={<AuthLayout><LoginForm /></AuthLayout>} />
      <Route path="/register" element={<AuthLayout><SignupForm /></AuthLayout>} />
      <Route path="/" element={<HomeLayout />} />
      <Route path="/contacts/*" element={<ContactPage />} />
      <Route path="/users/*" element={<UsersPage />} />
      <Route path="/areas/*" element={<AreasPage />} />
      <Route path="/plans/*" element={<PlanPage />} />
    </Routes>
    </UserProvider>
    </PlanProvider>
      </ContactsProvider>
  
    </RegionsProvider>
  );
}

export default App;



// import './App.css';
// import { Route, Routes } from 'react-router-dom';
// import AuthLayout from './layouts/AuthLayout';
// import HomeLayout from './layouts/HomeLayout';
// import ContactPage from './pages/Contact/ContactPage';
// import UsersPage from './pages/Users/UsersPage';
// import ContriesPage from './pages/Contries/ContriesPage';
// import LoginFrom from './auth/Login/LoginFrom';
// import SignupFrom from './auth/Signup/SignupFrom';
// import ProtectedRoute from './utils/ProtectedRoute';

// function App() {
//   return (
//     <Routes>
//       <Route path="*" element={<div>Page not Found</div>} />
//       <Route path="/login" element={<AuthLayout><LoginFrom /></AuthLayout>} />
//       <Route path="/register" element={<AuthLayout><SignupFrom /></AuthLayout>} />
//       <ProtectedRoute path="/" element={<HomeLayout />} />
//       <ProtectedRoute path="/contacts" element={<HomeLayout><ContactPage /></HomeLayout>} />
//       <ProtectedRoute path="/users" element={<HomeLayout><UsersPage /></HomeLayout>} />
//       <ProtectedRoute path="/contries" element={<HomeLayout><ContriesPage /></HomeLayout>} />
//     </Routes>
//   );
// }

// export default App;


// import './App.css';
// import { Route,Routes } from 'react-router-dom';
// import AuthLayout from './layouts/AuthLayout';
// import HomeLayout from './layouts/HomeLayout';
// import ContactPage from './pages/Contact/ContactPage';
// import UsersPage from './pages/Users/UsersPage';
// import CountriesPage from './pages/Contries/ContriesPage';
// import LoginForm from './auth/Login/LoginForm';
// import SignupForm from './auth/Signup/SignupForm';
// import ProtectedRoute from './utils/ProtectedRoute';

// function App() {
//   return (
//     <Routes>
//       <Route path="*" element={<div>Page not Found</div>} />
//       <Route path="/login" element={<AuthLayout><LoginForm /></AuthLayout>} />
//       <Route path="/register" element={<AuthLayout><SignupForm /></AuthLayout>} />
//       <ProtectedRoute path="/" element={<HomeLayout />} />
//       <ProtectedRoute path="/contacts" element={<ContactPage />} />
//       <ProtectedRoute path="/users" element={<UsersPage />} />
//       <ProtectedRoute path="/countries" element={<CountriesPage />} />
//     </Routes>
//   );
// }

// export default App;


