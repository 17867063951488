import React, { useRef, useState } from 'react';
import HomeLayout from '../../layouts/HomeLayout';
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import AddContact from './AddContact';
import TopLayout from '../../layouts/TopLayout';
import Table from '../../components/Tables/Table';
import { useContactsContext } from '../../hooks/ContantsContext';
import EditContact from './EditContact';
import EditIcon from "../../assets/icons/GoldEdit.svg"
import axiosConfig from '../../utils/axiosConfig';
import SearchBar from '../../components/SearchBar/SearchBar';
import * as XLSX from 'xlsx';
import CustomAlert from '../../components/Alert/CustomAlert';
import CloudUpdate from "../../assets/icons/cloud-upload.svg"
import { useLocation, useNavigate } from 'react-router-dom';

function ContactPage() {
    const { contacts, refreshContacts ,setSelectedContact  ,netError,setNetError,netLoading} = useContactsContext();

    const [addModalShow, setAddModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const [fileLoading, setFileLoading] = useState(false); // Loading state
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    const openAddPopup = () => {
        setAddModalShow(true);
        navigate(`${location.pathname}/addNewContact`,{ replace : false });
    };

    const closeAddPopup = () => {
        setAddModalShow(false);
        refreshContacts();
        navigate(`/contacts`);
    };

    const openEditPopup = (contact) => {
        setEditModalShow(true);
        // navigate(`${location.pathname}/edit/${contact.id}`)
        setSelectedContact(contact)
    };

    const closeEditPopup = () => {
        setEditModalShow(false);
        refreshContacts();
        navigate("/contacts",{replace : false})
    };

    const handleErrorClose = () => {
        setError(null);
    };

    const handleSuccessClose = () => {
        setSuccess(null);
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const excelFile = new FormData();
                excelFile.append('file', file); // Use jsonData here

                setFileLoading(true);
                const response = await axiosConfig.post('/contact/import-contacts', excelFile, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                });
                setSuccess("تمت إضافة الملف بنجاح")
                refreshContacts();
                setError(null);
            } catch (error) {
                setSuccess(null);
                setError(error.response?.data?.message || 'An error occurred');
            } finally {
                setFileLoading(false);
                event.target.value = '';
            }
        }
    };

    const openFilePicker = () => {
        const fileInput = document.getElementById("excelFileInput");
        fileInput.click();
    };

    // Define the content you want to render within Upbar
    const upBarContent = (
        <>
            <Button
                t_color={colors.white}
                b_color={colors.light_gold}
                className="new-contact-btn"
                onClick={openAddPopup}
            >
                إضافة جهة اتصال
            </Button>
            <Button
                t_color={colors.white}
                b_color={colors.light_gold}
                className="new-contact-btn"
                onClick={openFilePicker}
            >
                {fileLoading ? "جار التحميل " : <span><img src={CloudUpdate} alt="Upload Icon" />  اضف من ملف  </span>}
            </Button>
            <input
                id="excelFileInput"
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileChange}
                style={{ display: "none" }}
                ref={fileInputRef}
            />
        </>
    );

    const EditButton = (contact) => (
        <Button
            className="edit_icon"
            onClick={() => openEditPopup(contact)}
        >
            <img src={EditIcon} />
        </Button>
    );

    const [searchResults, setSearchResults] = useState([]); // State to store search results
    const handleSearch = async (query) => {
        setLoading(true);
        if (!query) {
            setLoading(false);
            setSearchResults([]); // If there's no query, clear search results
        } else {
            try {
                const response = await axiosConfig.get(`/contact/view?search=${query}`);
                console.log(response, "Searchhhhhh");
                // Assuming the response is an array directly
                setSearchResults(response.data); // Set search results from API response
            } catch (error) {
                console.error('Error searching Contacts:', error);
            } finally {
                setLoading(false);
            }
        }
    };
    

    const columns = ['تاريخ الاشتراك', 'اسم المسستخدم', 'الرقم', 'العنوان',' '];
    const data = searchResults.length > 0 ? searchResults.map((contact) => [
        contact.created_at.split('T')[0],
        <div>{contact.firstname} ابن {contact.father_name} {contact.lastname}</div>,
        contact.phone, 
        <div>
        {
            Array.isArray(contact.area) && contact.area.length > 0 ? (
                <div>
                    {contact.area[0].ar_name}
                </div>
            ) : (
                <div style={{color:"#efefef"}}>
                   {contact.areas_notes}
                </div>
            )
        }
    </div>,
        EditButton(contact)
    ]) : contacts.map((contact) => [
        contact.created_at.split('T')[0],
        <div>{contact.firstname} ابن {contact.father_name} {contact.lastname}</div>,
        contact.phone,
        <div>
        {
            Array.isArray(contact.area) && contact.area.length > 0 ? (
                <div>
                    {contact.area[0].ar_name}
                </div>
            ) : (
                <div style={{color:"#efefef"}}>
                   {contact.areas_notes}
                </div>
            )
        }
    </div>,
        EditButton(contact)
    ]);
    const handleNetError = ()=>{
        setNetError(null);
        refreshContacts();
    }
    return (
        <>
            <HomeLayout
                page_title="قائمة الأسماء"
                upBarContent={upBarContent}
                mainContent={
                    <TopLayout
                        className="top_full"
                        title="القائمة"
                        searchBar={<SearchBar onSearch={handleSearch} />}
                        children={
                            netLoading ? (
                                <p>جاري التحميل...</p>
                            ):
                            netError ? (
                                <div>
                                    <CustomAlert
                                        message={netError}
                                        btnName="أعد المحاولة"
                                        onClose={handleNetError}
                                    />
                                </div>
                            ) : loading ?(
                                    <p>جاري البحث...</p>
                            ):  contacts.length > 0 || searchResults.length > 0 ? (                            
                            <Table
                                responsiveClassName={'conatact_height_2'}
                                tableClassName={"with_edit"}
                                columns={columns}
                                data={data}
                            />):(
                                "لايوجد أسماء"
                            )
                        }
                    />
                }
            />
            {addModalShow && <AddContact onClose={closeAddPopup} />}
            {editModalShow && <EditContact onClose={closeEditPopup} />}
            {error && <CustomAlert message={error} onClose={handleErrorClose} />}
            {success && <CustomAlert message={success} onClose={handleSuccessClose} />}
        </>
    );
}

export default ContactPage;
