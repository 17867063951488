import React from 'react'
import logo from '../assets/images/Mask group.svg'
import whitelogo from "../assets/images/Mask group white.svg"
import "./AuthLayout.css"
import colors from '../components/Colors/color'

function AuthLayout({ children }) {
  return (
    <div className='container-fluid auth-home'>
      <div className="login-page-logo" style={{backgroundColor:colors.gold}}>
        <img src={logo} className='ring' alt="logo" />
        {children}
      </div>
      <div className='white-logo'>
        <img src={whitelogo} className='logo' alt="logo" />
      </div>
    </div>
  )
}

export default AuthLayout
