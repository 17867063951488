import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MyModal from '../../components/Popup/MyModal';
import Form from '../../components/Forms/Form';
import axios from '../../utils/axiosConfig';
import "../../components/Forms/FormStyles.css"
import CustomAlert from '../../components/Alert/CustomAlert';

function AddPlan({ onClose }) {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fields = [
    { label: 'اسم الباقة', type: 'text', name: 'name' },
    { label: 'عدد الرسائل', type: 'text', name: 'count_of_message' },
    { label: 'السعر', type: 'text', name: 'price' },
    { label: 'العملة', type: 'text', name: 'currency' },
  ];
  const handleErrorClose = () => {
    setError(null);
  };

  const createPlan = async (formData) => {
    try {
      setLoading(true);
      const response = await axios.post('/plan/add', formData); // Adjust the API endpoint as needed
      console.log("res", response.data); // Handle the response data as needed
      // Redirect user to another page upon successful form submission
      navigate("/plans");
      onClose(); // Close the modal after successful form submission
    } catch (error) {
      console.error('Error occurred during form submission:', error);
      setError(error.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='modal-categories'>
      <MyModal
        title="إضافة باقة"
        // className="modal"
        show={true} // Always keep the modal open
        onHide={onClose}
        body={
          <>
            {/* {error && <div className="error-message">{error}</div>} */}
            <Form
              form_className="contact-form-container"
              form_title="إضافة باقة"
              fields={fields}
              formRef={formRef}
              onSubmit={createPlan}
              btn_className='add_from_popup'
              btn_text={loading ? "تتم الإضافة..." : "إضافة"}
              // disabled={loading}
            />
             {error !== null && <CustomAlert message={error}  onClose={handleErrorClose} />}
          </>
        }
      />
    </div>
  );
}

export default AddPlan;
