import React, { useEffect, useState } from 'react';
import "./ButtonStyles.css";
import axiosConfig from '../../utils/axiosConfig';
import CustomAlert from '../Alert/CustomAlert';
import { useUsersContext } from '../../hooks/UsersContext';

const ToggleButton = ({apiEndpoint ,isActiveValue ,contactId}) => {
  // console.log("contactId",contactId)
  const {refreshUsers} =useUsersContext();
  const [error,setError] =useState(null)
  const [isActive, setIsActive] = useState(isActiveValue);

  useEffect(() => {
    setIsActive(isActiveValue);
  }, [isActiveValue]);

  const handleCloseError =() =>{
    setIsActive(isActiveValue);
    setError(null);
  }


  const handleToggle = async () => {
    const newIsActive = isActive ? false : true;  // Using boolean values
    setIsActive(newIsActive);
    console.log("isActive:", newIsActive);
  
    try {
      const response = await axiosConfig.put(apiEndpoint, { id: contactId, approved: newIsActive });
      console.log("status", response);
  
      if (response.status === 200 && response.data.success === 1) {
        console.log("Success update status:", response.data.msg);
        console.log(response.data);
        refreshUsers();
        setError(null)
      } else {
        console.log("Error update status:", response.data.msg);
      }
    } catch (error) {
      console.error("Error updating status:", error);
      setError("حدث خطأ أثناء ")
    }
  }
  

  return (
    <>
        <div className={`toggle-button ${isActive ? 'active' : ''}`} onClick={handleToggle}>
      <div className="slider"></div>
    </div>
    {error && <CustomAlert message={error} onClose={handleCloseError}/>}
    </>

  );
};

export default ToggleButton;