import React, { useState } from 'react'
import HomeLayout from '../../layouts/HomeLayout'
import TopLayout from '../../layouts/TopLayout';
import ButtomLayout from '../../layouts/ButtomLayout';
import UserInfo from './UserInfo';
import AddedContact from '../Contact/AddedContact';
import Table from '../../components/Tables/Table';
import { useUsersContext } from '../../hooks/UsersContext';
import SearchBar from '../../components/SearchBar/SearchBar';
import axiosConfig from '../../utils/axiosConfig';
import Button from '../../components/Buttons/Button';
import CustomAlert from '../../components/Alert/CustomAlert';

function UsersPage() {

    const {users,setSelectedUser,refreshUsers ,netError,setNetError,netLoading} = useUsersContext();
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [loading ,setLoading] =useState(false);

    const handleNetError = ()=>{
        setNetError(null);
        refreshUsers();
    }

    const handleRowClick = (rowData) => {
        // Find the area based on the clicked row data
        let resultUser;
    
        // First, try to find the user in the search results
        if (searchResults && searchResults.length > 0) {
            const areaIndex = searchResults[rowData]; // Access the ID using the row index
            resultUser =areaIndex;
            // resultUser = searchResults.find(user => user.name === rowData[1]);
        }
        
        // If not found in search results, try to find in the users array
        if (!resultUser) {
            const areaIndex = users[rowData]; // Access the ID using the row index
            resultUser = areaIndex;
        }
        
        // Check if user is found
        if (resultUser) {
            setSelectedUser(resultUser);
            setSelectedRowIndex(rowData);
            console.log('Clicked User:', resultUser);
        } else {
            console.log('User not found');
        }
    };

    const columns = ['تاريخ الاشتراك', 'اسم المسستخدم', 'البريد الالكتروني', 'الباقة'];

    const [searchResults, setSearchResults] = useState([]); // State to store search results

    const handleSearch = async (query) => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/user/view?search=${query}`); // Replace with your search API endpoint
            setSearchResults(response.data.user.original); // Set search results from API response
            setSelectedRowIndex(null)
            setSelectedUser(null)
            console.log(response.data.user.original , "res")
        } catch (error) {
            console.error('Error searching users:', error);
            setSelectedRowIndex(null)
            setSelectedUser(null)
            setSearchResults([])
            // Handle error
        }finally{
            setLoading(false);
        }
    };

    const data =searchResults && searchResults.length > 0 ? searchResults.map((user) => [
        user.created_at.split("T")[0],
        user.name,
        user.email,
        user.plan && user.plan.plan && user.plan.plan.ar_name
    ]) : users && users.map((user) => [
        user.created_at.split("T")[0],
        user.name,
        user.email,
        user.plan && user.plan.plan && user.plan.plan.ar_name
    ]);

    return (
        <HomeLayout
            page_title="إدارة المستخدمين"
            mainContent={
                <>
                    <TopLayout
                        className="top_section"
                        title="القائمة"
                        searchBar={<SearchBar  onSearch={handleSearch}/>}
                        children={
                            netLoading ? (
                                <p>جاري التحميل...</p>
                            ):
                            netError ? (
                                <div>
                                    <CustomAlert 
                                        message={netError}
                                        btnName="أعد المحاولة"
                                        onClose={handleNetError}
                                    />
                                   
                                </div>
                            ) : loading ?(
                                    <p>جاري البحث...</p>
                            ): (
                                users.length > 0 || searchResults.length > 0 ? (
                                    <Table
                                        columns={columns}
                                        data={data}
                                        onRowClick={handleRowClick}
                                        selectedRowIndex={selectedRowIndex}
                                    />
                                ) : (
                                    "لا يوجد مستخدمين"
                                )
                            )
                        }                        
                        // children={
                        //     {netError ? (
                        //             <Button> أعد المحاولة </Button>
                        //     ) :(
                        //         users.length >0 || searchResults.lenght >0 ? (
                        //             <Table
                        //             columns={columns}
                        //             data={data}
                        //             onRowClick={handleRowClick}
                        //             selectedRowIndex ={selectedRowIndex}
                        //             />):("لا يوجد مستخدمين")
                        //     )}
                          
                        // }        
                    />
                    <ButtomLayout
                        children={
                            <>
                            {/* remi */}
                                <UserInfo />
                                
                                <AddedContact />
                            </>

                        }

                    />
                </>
            }
        />
    )
}

export default UsersPage
