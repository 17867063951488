import React, { useState } from 'react'
import AuthLayout from '../../layouts/AuthLayout'
import axios from "../../utils/axiosConfig"
import { FaUser } from "react-icons/fa6";
import { FaLock } from "react-icons/fa";
import '../Login/LoginFrom.css'
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import Form from '../../components/Forms/Form';
import { useNavigate } from 'react-router-dom';
import CustomAlert from '../../components/Alert/CustomAlert';

function SignupForm() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error ,setError]=useState(null);

  const fields = [
    { placeholder:' ادخل الاسم المستخدم', type: 'text', name: 'username' },
    { placeholder:' ادخل الايميل   ', type: 'text', name: 'email' },
    { placeholder:' ادخل كلمة السر ', type: 'password', name: 'password' },
  ];
  const handleErrorClose = () => {
    setError(null);
  };

  const SignupSubmit = async (formData) => {
    console.log("form " ,formData)
    try {
      setLoading(true);
      const response = await axios.post('/auth/register', formData); // Adjust the API endpoint as needed
      console.log(response.data); // Handle the response data as needed
      setError(null)
      navigate("/");
    } catch (error) {
      console.error('Error occurred during Signup:', error);
      setError('Error occurred during Signup')
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Form
  form_className="login-form-container"
  form_title="إنشاء حساب"
   fields={fields}
   showLink={true}
   linkNav="/login"
   linkText="هل لديك حساب مسبقاً؟"
   onSubmit={SignupSubmit} // Pass the handleSubmit function as onSubmit prop
   btn_className="login-btn"
   btn_text={loading ? "يتم إنشاء حساب..." : "إنشاء حساب"}
  disabled={loading}
  />
     {error !== null && <CustomAlert message={error}  onClose={handleErrorClose} />}
    </>

  )
}

export default SignupForm
