import React, { useState } from 'react'
import MyModal from '../../components/Popup/MyModal'
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import axiosConfig from '../../utils/axiosConfig';
import { useUsersContext } from '../../hooks/UsersContext';
import "../../components/Forms/FormStyles.css"
import CustomAlert from '../../components/Alert/CustomAlert';

function ExistingArea({ onClose, newAreaName, area_id }) {
    const { setSelectedUser, refreshUsers } = useUsersContext();
    const [moveContact, setMoveContact] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const editArea = async () => {
        try {
            setLoading(true);
            if (moveContact) {
                // Perform the edit only if the checkbox is checked
                const response = await axiosConfig.put(`/contact/edit`, {
                    id: newAreaName.id,
                    areas_id: area_id,
                    areas_notes: " "
                });
                console.log("res edit area user ", response)
                // setSelectedUser(null);
                // refreshUsers();
            }
            onClose(); // Close the modal whether the edit was performed or not
        } catch (error) {
            console.error('Error occurred during form submission:', error);
            setError(error.response?.data?.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    }

    const handleCloseError =() =>{
      setError(null);
    }

    return (
      <>
        <div className='modal-categories'>
            <MyModal
                show={true} // Always keep the modal open
                onHide={onClose}
                body={<>
                    {newAreaName &&
                        <>
                            <p style={{
                              
                                backgroundColor: "#eee", display: "inline-block",
                                padding: "10px", borderRadius: "5px", fontWeight: "bold"
                            }}>
                                اسم المدينة المضاف :
                                <span style={{ color: "#AB9354", paddingInlineStart: "10px" }}>
                                    {newAreaName.areas_notes}
                                </span>
                            </p>
                            <p style={{
                                backgroundColor: "#eee", display: "inline-block",
                                padding: "10px", borderRadius: "5px", fontWeight: "bold"
                            }}>
                                إن هذه المدينة موجودة بالفعل
                            </p>
                        </>
                    }
                    <form className="existing-area-form-container">
                      <div className='fields'>
                      <label>نقل جهة الاتصال لهذ المنطقة</label>
                        <input
                            type='checkbox'
                            checked={moveContact}
                            onChange={(e) => setMoveContact(e.target.checked)}
                        />
                      </div>

                        <div className='edit_buttons_div'>
                        <Button
                            onClick={editArea}
                            className="cansle_from_popup"
                            b_color={colors.light_gold}
                        >
                         {loading ? "جار التعديل" : "تعديل"}
                        </Button>
                        </div>

                    </form>
                </>}
            /> 
        </div>
        {error && <CustomAlert message={error} onClose={handleCloseError}/>}
        </>
    )
}

export default ExistingArea
