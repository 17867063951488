import React, { useState } from 'react'
import "./PackagesStyles.css"
import { usePlansContext } from '../../../hooks/PlansContext';
import EditPlan from '../EditPlan';
import Button from '../../../components/Buttons/Button';
import colors from '../../../components/Colors/color';
import ToggleButton from '../../../components/Buttons/ToggleActiveButton';
import "../../../components/Forms/FormStyles.css"
import { useLocation, useNavigate } from 'react-router-dom';

function PackageCard({plan}) {
  const [editModalShow ,setEditModalShow]=useState(false);
  const { refreshPlans,setSelectedPlan} = usePlansContext();
  const navigate = useNavigate();
  const location = useLocation();

  const openEditPopup = (plan) => {
    console.log("plan",location.pathname)
    setEditModalShow(true);
    // navigate(`${location.pathname}/edit/${plan.id}`, { replace: false });
    setSelectedPlan(plan)
};

const closeEditPopup = () => {
    setEditModalShow(false);
    refreshPlans();
    // navigate("/plans")
};
  return (
    <>
      <div className='package_card' style={{backgroundColor:colors.white}}>
        <div className='inner_box' style={{backgroundColor:colors.gold , color:colors.white}}>
        <h6>{plan.name}</h6>
          <p className='number_message'>{plan.count_of_message}</p>
          <p className='price'>{plan.price}</p>
          <div className='edit_buttons_div'>
          <Button
          className='cansle_from_popup'
          t_color={colors.light_gold}
          b_color={colors.white}
          onClick={() => openEditPopup(plan)}
          >
            تعديل
          </Button>
          {/* <ToggleButton/> */}
          </div>
        </div>
    </div>
    {editModalShow && <EditPlan onClose={closeEditPopup} />}
    </>
  
  )
}

export default PackageCard
