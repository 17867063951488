import React, { useEffect, useState } from 'react'
import HomeLayout from '../../layouts/HomeLayout'
import ContactTable from '../Contact/ContactTable'
import Button from '../../components/Buttons/Button';
import colors from '../../components/Colors/color';
import AddedContact from '../Contact/AddedContact';
import { useRegionsContext } from '../../hooks/RegionsContext';
import TopLayout from '../../layouts/TopLayout';
import ButtomLayout from '../../layouts/ButtomLayout';
import AddArea from './AddArea';
import Table from '../../components/Tables/Table';
import EditArea from './EditArea';
import axios from "../../utils/axiosConfig"
import "../Contact/BodyStyles.css"
import EditIcon from "../../assets/icons/GoldEdit.svg"
import axiosConfig from '../../utils/axiosConfig';
import SearchBar from '../../components/SearchBar/SearchBar';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomAlert from '../../components/Alert/CustomAlert';
// import UsersDetails from './UsersDetails'

function AreasPage() {

    const { areas, selectedArea, setSelectedArea, refreshAreas ,netError,setNetError,netLoading } = useRegionsContext();
    const [contact, setContact] = useState([])
    const [addModalShow, setAddModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);

   

    const openAddPopup = () => {
        setAddModalShow(true);
        navigate(`${location.pathname}/addNewArea`,{ replace : false })
    };
    const closeAddPopup = () => {
        setAddModalShow(false);
        refreshAreas();
        navigate('/areas')
    };

    const openEditPopup = () => {
        setEditModalShow(true);
    };
    const closeEditPopup = () => {
        setEditModalShow(false);
        refreshAreas();
        navigate('/areas')
    };
    const EditButton = (area) => (
        <>
            <Button
                className="edit_icon"
                onClick={() => openEditPopup(area)}
            >
                <img src={EditIcon} />
            </Button>
        </>
    );

    const upBarContent = (
        <Button
            t_color={colors.white}
            b_color={colors.light_gold}
            className="new-contact-btn"
            onClick={openAddPopup}
        >
            إضافة بلدة
        </Button>
    );
    const handleRowClick = (rowData) => {
        console.log("rowData ", rowData);

                // Find the area based on the clicked row data
                let resultArea;
    
                // First, try to find the user in the search results
                if (searchResults && searchResults.length > 0) {
                    const areaIndex = searchResults[rowData]; // Access the ID using the row index
                    resultArea =areaIndex;
                }
                
                // If not found in search results, try to find in the users array
                if (!resultArea) {
                    const areaIndex = areas[rowData]; // Access the ID using the row index
                    // resultArea = areas.find(area => area.id === rowData);
                     resultArea = areaIndex;

                }
                
                // Check if user is found
                if (resultArea) {
                    setSelectedArea(resultArea.area);
                    // navigate(`${location.pathname}/edit/${resultArea.area.ar_name}`)
                    setContact(resultArea.contacts);
                    setSelectedRowIndex(rowData)
                    console.log('Clicked Area:', resultArea.area);
                } else {
                    console.log('Area not found');
                }
    };

    const [searchResults, setSearchResults] = useState([]); // State to store search results
    const handleSearch = async (query) => {
        setLoading(true)
        if (!query) {
            setSearchResults(null); // If there's no query, clear search results
        } else {
            setLoading(true)
            try {
                const response = await axiosConfig.get(`/area/view?search=${query}`); // Replace with your search API endpoint
                setSearchResults(response.data.posts.original); // Set search results from API response
                console.log(response.data.posts.original, "search res");
            } catch (error) {
                console.error('Error searching areas:', error);
                // Handle error
            }finally{
                setLoading(false)
            }
        }
    };
    

    const columns = ['اسم البلدة', 'عدد جهات الاتصال',''];

    const data = searchResults && searchResults.length > 0 ? searchResults.map((area) => [
        area.area.ar_name, area.count_of_contacts
    ]) : areas && areas.map((area) => [
        // console.log(area.area),
       area.area.ar_name,
         area.count_of_contacts
        //  , area.area.id 
    ]);

    
    const area_contact = contact && contact.map(({ firstname, fathername, lastname, phone, area }) => [
        <div>
            {firstname} ابن  {lastname} {fathername}
        </div>,
        phone
    ]);

    // useEffect(() => {
    //     console.log("selected ", selectedArea)
    //     if (selectedArea) {
    //         console.log("contact ", contact)
    //     }
    // }, [selectedArea]);

    const handleNetError = ()=>{
        setNetError(null);
        refreshAreas();
    }

    return (
        <HomeLayout
            page_title="إدارة المناطق"
            upBarContent={upBarContent}
            mainContent={
                <>
                    <TopLayout
                        className="top_section"
                        title="البلدات الحالية"
                        searchBar={<SearchBar  onSearch={handleSearch}/>}
                        children={
                            netLoading ? (
                                <p>جاري التحميل...</p>
                            ):
                            netError ? (
                                <div>
                                    <CustomAlert
                                        message={netError}
                                        btnName="أعد المحاولة"
                                        onClose={handleNetError}
                                    />
                                   
                                </div>
                            ) 
                            : loading ?(
                                    <p>جاري البحث...</p>
                            )
                            : (
                                areas.length > 0 || searchResults.length > 0 ? (
                            <Table
                                tableClassName={"with_edit"}
                                columns={columns}
                                data={data}
                                children={
                                    EditButton()
                                }
                                onRowClick={handleRowClick}
                                selectedRowIndex={selectedRowIndex}
                            />
                        ):("لا يوجد مناطق")
                            )
                        }
                    />
                    <ButtomLayout
                        tableClassName="buttom_full"
                        children={
                            contact && contact.length > 0 ? (
                                <ContactTable 
                                data={area_contact} 
                                />
                            ) : (
                                <div>No Contacts in this Area</div>
                            ) 
                        }
                    />
                    {addModalShow && <AddArea onClose={closeAddPopup} />}
                    {editModalShow && <EditArea onClose={closeEditPopup} />}
                </>
            }
        />
    )
}

export default AreasPage
