import React from 'react'
import "./HomeLayoutStyles.css"
// import Sidebar from "../components/NavigatorBars/Sidebar"
import Upbar from '../components/NavigatorBars/Upbar'
import ResponsiveSidebar from '../components/NavigatorBars/ResponsiveSidebar'

function HomeLayout({ page_title,upBarContent, mainContent }) {
  return (
    <div className='container-fluid home'>
      <div className='upBar'>
        <Upbar page_title={page_title}  upBarContent={upBarContent}/>
        {/* upbar */}
      </div>
      <div className='sideBar '>
        <ResponsiveSidebar/>
        {/* <Sidebar /> */}
      </div>
      <div className='main' >
       {mainContent}
      </div>
    </div>
  )
}

export default HomeLayout
