import React ,{useState} from 'react'
import colors from '../../components/Colors/color'
import Table from '../../components/Tables/Table';
import Button from '../../components/Buttons/Button';
import { useUsersContext } from '../../hooks/UsersContext';
import WiteEditIcon from "../../assets/icons/WhiteEdit.svg"
import ToggleButton from '../../components/Buttons/ToggleActiveButton';
import AddArea from "../Areas/AddArea"
import AddContact from "./AddContact"
import { useRegionsContext } from '../../hooks/RegionsContext';
import { useContactsContext } from '../../hooks/ContantsContext';
import { useLocation, useNavigate } from 'react-router-dom';
import ExistingArea from '../Areas/ExistingArea';

function AddedContact({ tableClassName }) {

  const { selectedUser } = useUsersContext();
  const {refreshAreas,areas} = useRegionsContext();
  const {setSelectedContact} = useContactsContext();
  const [clickedContact, setClickedContact]= useState();
  const [existing_area_id, set_existing_area_id]= useState();
  const [add_area_popup,set_add_area_popup]= useState(false);
  const [existing_area_popup,set_existing_area_popup]= useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // const [add_contact_popup,set_add_contact_popup]= useState(false);

  const handlePopup = (contact) => {
    set_add_area_popup(true);
    setClickedContact(contact)
    // navigate(`areas/addNewArea`,{ replace : true }) 
  };
  const closeAddAreaPopup = () => {
    set_add_area_popup(false);
    refreshAreas();
    // navigate(`/users`,{ replace : false })
};


const handleExisitingAreaPopup = (contact,area_id) => {
  console.log("area_id" ,area_id)
  set_existing_area_id(area_id);
  set_existing_area_popup(true);
  setClickedContact(contact)
  // navigate(`areas/addNewArea`,{ replace : false })
};
const closeExisitingAreaPopup = () => {
  set_existing_area_popup(false);
  // refreshAreas();
  // navigate(`/users`,{ replace : false })
};


  const added_contact = selectedUser && selectedUser.contacts && selectedUser.contacts.map((contact) => (
    [
      <div key={contact.id}>
        {contact.firstname} ابن {contact.lastname} {contact.father_name}
      </div>,
      <div>
      

{contact.area && contact.area[0].id !== 1 ? (
  contact.area[0].ar_name
) : (
  contact.areas_notes && areas.some(area => area.area.ar_name === contact.areas_notes) ? (
    <span onClick={() => {
      const matchedArea = areas.find(area => area.area.ar_name === contact.areas_notes);
      if (matchedArea) {
        handleExisitingAreaPopup(contact, matchedArea.area.id);
      }
    }}> غير ذلك</span>
  ) : (
    <span onClick={() => handlePopup(contact)}>غير ذلك</span>
  )
)}


    </div>,
     <ToggleButton isActiveValue={contact.approved} apiEndpoint={`/contact/edit`} contactId={contact.id}/>
    ]
  ));
  const handleRowClick = (rowData) => {

    const clickedId = rowData[rowData.length - 1];
    console.log('Contact click', clickedId);
    // Find the contact object based on the clicked ID
    const resultContact = selectedUser.contacts.find(contact => contact.id === clickedId);
    
    // Check if a matching contact is found
    if (resultContact) {
        setClickedContact(resultContact);
        console.log('Clicked Contact:', resultContact);
    } else {
        console.log('Contact not found');
    }
};

console.log("selected Useeeeer" , selectedUser)
  return (
  <>
    <div>
      <p className='title'>جهات الاتصال المضافة</p>
      {!selectedUser ? (
        <div>No User Selected</div>
      ) : (
        <>
          {added_contact && added_contact.length > 0 ? (
            <Table
              responsiveClassName={'conatact_height'}
              tableClassName={'added-contact'}
              data={added_contact}
              childrenClassName="added_children"
              onRowClick={handleRowClick}
            />
          ) : (
            <div>No Added Contact</div>
          )}
        </>
      )}
    </div>
    {add_area_popup && <AddArea
     onClose={closeAddAreaPopup} 
    newAreaName={clickedContact}/>}
     {existing_area_popup && <ExistingArea
     onClose={closeExisitingAreaPopup} 
    newAreaName={clickedContact}
    area_id ={existing_area_id}
    />}
    </>
  )
}

export default AddedContact
