import React, { useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';

function MyModal(props) {
  const formRef = useRef(null);

  const handleSubmitAndClose = () => {
      if (formRef.current) {
          formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
      }
      props.onHide();
  };

    return (
        <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        {/* <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
        {props.body}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
          <Button variant="primary" onClick={props.onHide}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    );
}

export default MyModal;