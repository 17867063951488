// AppContext.js

import axios from "../utils/axiosConfig"
import React, { createContext, useContext, useEffect, useState } from 'react';

// Creating a context
const PlansContext = createContext();

// Custom hook to use the context
export const usePlansContext = () => useContext(PlansContext);

// Provider component to wrap around components that need access to the context
export const PlanProvider = ({ children }) => {

    const [netError,setNetError] =useState(null);
    const [netLoading, setNetLoading] = useState(true);

    // Areas variables for regions data and loading status
    const [plans, setPlans] = useState([]);
    const [selectedPlan , setSelectedPlan] = useState(null)
    // Function to fetch states in context
    const fetchPlan = async () => {
        try {
            const response = await axios.get('/plan/view');
            console.log("plansss: ", response.data.posts.original);

            if (response.status === 200) {
                // console.log(response.data.message,"get All plans");
                // console.log("res: ", response);
                // console.log("res: ", response.data.data);
                setPlans(response.data.posts.original);
                setNetError(null);
            } else {
                console.log("error ", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching plans:", error);
            setNetError("حدث خطأ أثناء التحميل. يرجى المحاولة مرة أخرى.");
        }finally{
            setNetLoading(false)
        }
    };
    // Function to update states in context
    const updatePlansInContext = (newPlan) => {
        setPlans(newPlan);
    };
    // Function to refresh states in context
    const refreshPlans = () => {
        setNetLoading(true);
        fetchPlan();
    };
 

    useEffect(() => {
        fetchPlan();
    }, []);


    return (
        <PlansContext.Provider
            value={{
             plans,refreshPlans,updatePlansInContext,
             selectedPlan,setSelectedPlan,
             netError,setNetError,
             netLoading
            }}
        >
            {children}
        </PlansContext.Provider>
    );
};
